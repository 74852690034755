import { fetch, fetchWithToken } from 'utils/fetch';
import { mapVendorOrder, mapVendorTask, mapBroker } from 'utils/mapping';
import { urlEncodeBody } from 'utils/requests';

export async function getOrderData(token: string): Promise<VendorOrderData> {
  const response = await fetchWithToken(`/order/${token}`);
  const data = await response.json();
  return {
    order: mapVendorOrder(data.order),
    task: mapVendorTask(data.task),
    broker: mapBroker(data.broker),
    address: data.propertyAddress
  };
}

type UploadForm = {
  files: string[];
  invoices: string[];
};

export async function uploadFiles(form: UploadForm, token?: string | null): Promise<void> {
  await fetch(`/order/${token}/upload/finish`, {
    method: 'POST',
    body: urlEncodeBody(form)
  });
}

export async function updateOrderStatus({
  token,
  action,
  note
}: {
  token: string;
  action: 'accept' | 'reject';
  note?: string;
}): Promise<void> {
  await fetchWithToken(`/order/${token}/status`, {
    method: 'POST',
    body: urlEncodeBody({ note, action })
  });
}

export async function uploadFileToS3(
  file: File,
  token: string,
  config?: { publicAccess: boolean; listing_id: string; doctype: string }
): Promise<{ url: string; documentId: string }> {
  // presign document
  const { presigned_url: presignedUrl, document_id: documentId } = await (
    await fetch(`/order/${token}/upload`, {
      method: 'POST',
      body: urlEncodeBody({
        filename: file.name,
        size: file.size,
        ...config
      })
    })
  ).json();
  // upload file to s3
  await fetch(presignedUrl, { method: 'PUT', body: file });
  // confirm upload success
  const { url } = await (
    await fetch(`/order/${token}/upload/${documentId}`, { method: 'PUT' })
  ).json();

  return { documentId, url };
}
