import React, { useCallback, useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { getOrderData, updateOrderStatus as updateOrderStatusRequest } from './api';

type ActionType = 'accept' | 'reject' | 'upload';

interface OrderContextValues {
  action: ActionType;
  token: string | null | undefined;
  orderData?: VendorOrderData;
  updateOrderStatus: (action: 'accept' | 'reject', note?: string) => Promise<void>;
  resolveOrder?: 'complete' | 'reject';
  setResolveOrder: React.Dispatch<React.SetStateAction<'complete' | 'reject' | undefined>>;
}

const OrderContext = React.createContext({} as OrderContextValues);

export const OrderContextWrapper = ({ children }) => {
  const [action] = useQueryParam<ActionType>('action');
  const [token] = useQueryParam('token', StringParam);
  const [orderData, setOrderData] = useState<VendorOrderData>();
  const [resolveOrder, setResolveOrder] = useState<'complete' | 'reject' | undefined>();

  const updateOrderStatus = useCallback(
    async (action: 'accept' | 'reject', note?: string) => {
      try {
        if (!token) throw Error('Token is missing');
        await updateOrderStatusRequest({ token, action, note });
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    },
    [token]
  );

  const getOrder = useCallback(async () => {
    if (!token || !action) return;
    try {
      const data = await getOrderData(token);
      setOrderData(data);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  }, [token]);

  useEffect(() => {
    if (!token) return;
    (async () => {
      try {
        getOrder();
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    })();
  }, [token]);

  useEffect(() => {
    if (action === 'upload') return;
    try {
      updateOrderStatus(action);
    } catch (err) {
      notify(tryGetFirstError(err));
    }

  }, [action]);

  const contextValues: OrderContextValues = {
    action,
    token,
    orderData,
    updateOrderStatus,
    resolveOrder,
    setResolveOrder
  };

  return <OrderContext.Provider value={contextValues}>{children}</OrderContext.Provider>;
};

export default OrderContext;
