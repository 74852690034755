import React from 'react';
import NoSSR from 'components/NoSSR';
import OrderPage from 'views/OrderPage';

const Order = () => {
  return (
    <NoSSR>
      <OrderPage />
    </NoSSR>
  );
};

export default Order;
