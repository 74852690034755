import React from 'react';
import clsx from "clsx";

import styled from '@emotion/styled';
import { Icon } from 'components';

const RequestResolved = ({ children, type }) => {
  return (
    <StyledRequestResolved className={clsx("request-completed", type)}>
      <div className="circle">
        <Icon name="checkmark" />
      </div>
      {children}
    </StyledRequestResolved>
  );
};

export default React.memo(RequestResolved);

const StyledRequestResolved = styled.div`
  margin: 150px auto;
  max-width: 600px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 34px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .icon {
    fill: ${props => props.theme.colors.blue};
  }
  &.complete {
    .circle {
      border: 1px solid ${props => props.theme.colors.blue};
    }
    .icon {
      fill: ${props => props.theme.colors.blue};
    }
  }
  &.reject {
    .circle {
      border: 1px solid ${props => props.theme.colors.red};
    }
    .icon {
      fill: ${props => props.theme.colors.red};
    }
  }
`;
