import React from 'react';
import dayjs from 'dayjs';

import styled from '@emotion/styled';
import { formatCurrency } from 'utils/rifmFormatters';

interface RequestInfoProps {
  orderData?: VendorOrderData;
}

const RequestInfo: React.FC<RequestInfoProps> = ({ orderData }) => {
  return (
    <StyledRequestInfo className="request-info">
      <h4 className="title">Request Information</h4>
      <table className="request-info-table">
        <tbody>
          <tr>
            <td>Type</td>
            <td>{orderData?.task.type}</td>
          </tr>
          <tr>
            <td>Property</td>
            <td>{orderData?.address || 'N/A'}</td>
          </tr>
          <tr>
            <td>Price</td>
            <td>{orderData ? formatCurrency(orderData.order.offeredPrice) : 'N/A'}</td>
          </tr>
          <tr>
            <td>Deadline</td>
            <td>
              {orderData?.order.deadline ? dayjs(orderData.order.deadline).format('MM/DD/YYYY') : 'N/A'}
            </td>
          </tr>
          <tr>
            <td>Broker</td>
            <td>{orderData?.broker.name || 'N/A'}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{orderData?.broker.email || 'N/A'}</td>
          </tr>
          <tr>
            <td>Phone</td>
            <td>{orderData?.broker.phone || 'N/A'}</td>
          </tr>
          <tr>
            <td>Note</td>
            <td>{orderData?.order.note}</td>
          </tr>
        </tbody>
      </table>
    </StyledRequestInfo>
  );
};

export default React.memo(RequestInfo);

const StyledRequestInfo = styled.div`
  flex-grow: 1;
  max-width: 475px;
  .request-info-table {
    width: 100%;
    border-collapse: collapse;
    td {
      padding: 20px 18px;
      border: 1px solid #eeeeee;
      &:first-of-type {
        color: ${props => props.theme.colors.grayDark};
      }
      &:last-of-type {
        padding: 20px 30px;
      }
    }
  }
`;
