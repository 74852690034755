import React from 'react';

import styled from '@emotion/styled';
import { Icon } from 'components';

const NotFound = () => {
  return (
    <StyledNotFound className="not-found">
      <div className="circle">
        <Icon name="close" />
      </div>
      This link is expired or broken. <br />
      Please reach out to the broker who gave it you to receive the new one.
    </StyledNotFound>
  );
};

export default React.memo(NotFound);

const StyledNotFound = styled.div`
  margin: 150px auto;
  max-width: 600px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 34px;
    width: 40px;
    height: 40px;
    border: 1px solid ${props => props.theme.colors.red};
    border-radius: 50%;
  }
  .icon {
    fill: ${props => props.theme.colors.red};
  }
`;
