import React, { useState, useContext } from 'react';
import pluralize from 'pluralize';

import styled from '@emotion/styled';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { Button, FileInput } from 'components';
import { uploadFiles, uploadFileToS3 } from './api';
import OrderContext from './OrderContext';

const UploadForm = () => {
  const { token, orderData, setResolveOrder } = useContext(OrderContext);
  const [files, setFiles] = useState<{ name: string; id: string; }[]>([]);
  const [invoices, setInvoices] = useState<{ name: string; id: string; }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const listingId = orderData?.task.listingId;

  const handleFileUpload = async (fileToAdd, setValue, doctype) => {
    setLoading(true);
    try {
      const options = {
        listing_id: listingId,
        public_access: 1,
        doctype
      };
      const { documentId } = await uploadFileToS3(fileToAdd, token, options);
      setValue(v => [...v, { name: fileToAdd.name, id: documentId }]);
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    try {
      await uploadFiles(
        {
          files: files.map(file => file.id),
          invoices: invoices.map(file => file.id)
        },
        token
      );
      setResolveOrder('complete');
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };


  return (
    <StyledUploadForm className="upload-form">
      <h4 className="title">Upload results</h4>
      <p className="upload-form__help">Supported file formats: pdf, doc, jpg</p>
      <FileInput
        label="Select files"
        onFileUpload={file => handleFileUpload(file, setFiles, 'ld_listing_photos')}
        onFileRemove={id => setFiles(files.filter(file => file.id !== id))}
        files={files}
        inputText={files.length ? `${files.length} ${pluralize('file', files.length)}` : ''}
        accept=".doc,.docx,.pdf,.jpg, .jpeg"
        showSpinner
        className="upload-form__input"
      />
      <h4 className="title">Upload invoice</h4>
      <p className="upload-form__help">Supported file formats: pdf, doc, jpg</p>
      <FileInput
        label="Select files"
        onFileUpload={file => handleFileUpload(file, setInvoices, 'ld_invoice')}
        onFileRemove={id => setInvoices(invoices.filter(file => file.id !== id))}
        files={invoices}
        inputText={invoices.length ? `${invoices.length} ${pluralize('invoice', invoices.length)}` : ''}
        accept=".doc,.docx,.pdf,.jpg, .jpeg"
        showSpinner
        className="upload-form__input"
      />
      <Button className="upload-form__btn" disabled={files.length < 1 || loading} onClick={handleSubmit}>Upload</Button>
    </StyledUploadForm>
  );
};

export default React.memo(UploadForm);

const StyledUploadForm = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 475px;
  .upload-form {
    &__help {
      margin-top: 0;
      font-size: 12px;
      color: ${props => props.theme.colors.grayDark};
    }
    &__btn {
      margin-top: 16px;
      height: 40px;
    }
  }
`;
